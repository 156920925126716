import React, { ReactNode, useEffect } from "react";
import titleStyles from "../displays/PageTitle.module.scss";

interface IntroTitleProps {
    title?:string
}

const IntroTitle: React.FC<IntroTitleProps> = (props) => {
  const { title } = props;

  return (
      <div className={titleStyles.titleContainer}>
        
        <div className={titleStyles.introText}>{title}</div>

      </div>

  );
};

export default IntroTitle;
