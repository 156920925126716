import React, { ReactNode, useEffect } from "react";
import titleStyles from "../displays/PageTitle.module.scss";

interface SectionTitleProps {
    title?:string
    titleSize?:number
}

const SectionTitle: React.FC<SectionTitleProps> = (props) => {
  const { title,titleSize } = props;

  return (
      <div className={titleStyles.titleContainer}>
        
        <div className={titleStyles.sectionText} style={{...titleSize&&{fontSize:titleSize}}}>{title}</div>

      </div>

  );
};

export default SectionTitle;
