import React from "react";
import { FC } from "react";
import PageContainer from "src/components/main/PageContainer";
import { useLanguage } from "src/contexts/language/use-language";
import assets from "src/assets/assets";
import BreakLine from "src/components/displays/BreakLine";
import BigPictureSection from "src/components/sections/BigPictureSection";
import IntroSection from "src/components/sections/IntroSection";
import GridSection from "src/components/sections/GridSection";
import BigPicturePointsSection from "src/components/sections/BigPicturePointsSection";
interface DeepinPageProps {}

const DeepinPage: FC<DeepinPageProps> = ({}) => {
  const { t } = useLanguage();



  return (
    <PageContainer fullImage={assets.HEADER_MAIN_BANNER}>
      
      <IntroSection
        centerContent={true}

        infoItem={{
          item_title: t["deepin.warning.title"],
          item_string: t["deepin.warning.description"],
        }}
      ></IntroSection>
<div style={{ backgroundColor: "#ffffff", height: 50 }}></div>

      <BreakLine />
      <div style={{ backgroundColor: "#ffffff", height: 50 }}></div>
<div style={{ backgroundColor: "#f7fbff", height:25}}></div>

      <IntroSection
        centerContent={true}
        backgroundColor={`#f7fbff`}

        infoItem={{
          item_title: t["deepin.title"],
          item_string: t["deepin.description"],
        }}
      ></IntroSection>
<div style={{ backgroundColor: "#f7fbff", height: 25 }}></div>

<GridSection gridOfRow={3}
     backgroundColor={`#f7fbff`}
  gridItem={[
    {
      item_name: t["deepin.feature_1"],
      item_description:t["deepin.feature_1_description"],

      image:"",
    },
    {
      item_name: t["deepin.feature_2"],
      item_description:t["deepin.feature_2_description"],

      image:"",
    }, 
    
    // {
    //   item_name: t["deepin.feature_6"],
    //   item_description:t["deepin.feature_6_description"],

    //   image:"",
    // },  
  ]}
/>
<GridSection gridOfRow={3}
     backgroundColor={`#f7fbff`}
  gridItem={[
   {
      item_name: t["deepin.feature_3"],
      item_description:t["deepin.feature_3_description"],

      image:"",
    },    {
      item_name: t["deepin.feature_4"],
      item_description:t["deepin.feature_4_description"],

      image:"",
    } 
    
    // {
    //   item_name: t["deepin.feature_6"],
    //   item_description:t["deepin.feature_6_description"],

    //   image:"",
    // },  
  ]}
/>
<GridSection gridOfRow={3}
     backgroundColor={`#f7fbff`}
  gridItem={[
      {
      item_name: t["deepin.feature_5"],
      item_description:t["deepin.feature_5_description"],

      image:"",
    },  
    {
      item_name: t["deepin.feature_6"],
      item_description:t["deepin.feature_6_description"],

      image:"",
    },  
  ]}
/>
<div style={{ backgroundColor: "#f7fbff", height: 50 }}></div>

<div style={{ backgroundColor: "#ffffff", height: 50 }}></div>

<BreakLine />
      

      <IntroSection
      
        centerContent={true}
        infoItem={{
          item_title: t["deepin.service.title"],
          item_string: t["deepin.service.description"],
        }}
      ></IntroSection>
      <div style={{ backgroundColor: "#ffffff", height: 25 }}></div>

      <GridSection gridOfRow={3}
        gridItem={[
          {
            item_name: t["deepin.service.feature_1"],
            item_description:t["deepin.service.feature_1_description"],

            image:"",
          },
          // {
          //   item_name: t["deepin.service.feature_2"],
          //   item_description:t["deepin.service.feature_2_description"],

          //   image:"",
          // },   
           {
            item_name: t["deepin.service.feature_3"],
            item_description:t["deepin.service.feature_3_description"],

            image:"",
          },
        ]}
     />
<div style={{ backgroundColor: "#ffffff", height: 50 }}></div>
     

    </PageContainer>
  );
};

export default DeepinPage;
