import React from "react";
import { FC } from "react";
import PageContainer from "src/components/main/PageContainer";
import { useLanguage } from "src/contexts/language/use-language";
import assets from "src/assets/assets";
import BreakLine from "src/components/displays/BreakLine";
import IntroSection from "src/components/sections/IntroSection";
import GridSection from "src/components/sections/GridSection";
interface EnvBuilderPageProps {}

const EnvBuilderPage: FC<EnvBuilderPageProps> = ({}) => {
  const { t } = useLanguage();



  return (
    <PageContainer fullImage={assets.HEADER_MAIN_BANNER}>
      
      <IntroSection
        centerContent={true}

        infoItem={{
          item_title: "開發環境搭建",
          item_string: "我們將持續改善技術並在未來提供更多環境開發的服務",
        }}
      ></IntroSection>
<div style={{ backgroundColor: "#ffffff", height: 50 }}></div>

      <BreakLine />
      <div style={{ backgroundColor: "#ffffff", height: 50 }}></div>
<div style={{ backgroundColor: "#f7fbff", height:25}}></div>

      <IntroSection
        centerContent={true}
        backgroundColor={`#f7fbff`}

        infoItem={{
          item_title: "服務範圍",
          item_string: "通常提供搭建React/React Native開發環境",
        }}
      ></IntroSection>
<div style={{ backgroundColor: "#f7fbff", height: 25 }}></div>

<GridSection gridOfRow={3}
     backgroundColor={`#f7fbff`}
  gridItem={[
    {
      item_name: "白板項目",
      item_description:"提供一個全新乾淨的項目，包括網站/APP並可以即時更改測試",

      image:"",
    },
    {
      item_name: "環境設置",
      item_description:"可教學或遠端安裝Android Studio/JDK/Node或有機會用到的功能的開發環境",

      image:"",
    }, 
    
    // {
    //   item_name: t["deepin.feature_6"],
    //   item_description:t["deepin.feature_6_description"],

    //   image:"",
    // },  
  ]}
/>

<GridSection gridOfRow={3}
     backgroundColor={`#f7fbff`}
  gridItem={[
      {
      item_name:"GitHub共同編輯",
      item_description:"將React/React Native上傳到GitHub並共享，並支援流程中遇到的問題",

      image:"",
    },  
    {
      item_name: "項目上架",
      item_description:"把React的項目上傳到網站平台，並支援流程中遇到的問題",

      image:"",
    },  
  ]}
/>
<div style={{ backgroundColor: "#f7fbff", height: 50 }}></div>

<div style={{ backgroundColor: "#ffffff", height: 50 }}></div>

<BreakLine />
      

      <IntroSection
      
        centerContent={true}
        infoItem={{
          item_title:  "搭建收費",
          item_string: "通常以小時計算費用，也可以用以日計",
        }}
      ></IntroSection>
      <div style={{ backgroundColor: "#ffffff", height: 25 }}></div>

      <GridSection gridOfRow={3}
        gridItem={[
          {
            item_name: "小時計",
            item_description:"每小時180$HKD，建議2小時或以上處理時間",

            image:"",
          },     {
            item_name: "半日計",
            item_description:"每日6小時 950$HKD",

            image:"",
          }
          // {
          //   item_name: t["deepin.service.feature_2"],
          //   item_description:t["deepin.service.feature_2_description"],

          //   image:"",
          // },   
       
        ]}
     />
<div style={{ backgroundColor: "#ffffff", height: 50 }}></div>
     

    </PageContainer>
  );
};

export default EnvBuilderPage;
