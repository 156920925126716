import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useLanguage } from "src/contexts/language/use-language";
import { Language } from "src/types/language";
import SelectToggle from "../inputs/SelectToggle";
import { PagePaths } from "../page-paths";
import styles from "./Header.module.scss";
import Menu from "./Menu";
interface HeaderProps {
  scrollTransparent?: boolean;
}
const switchColorThreshold = 299;
const Header: React.FC<HeaderProps> = ({ scrollTransparent }) => {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [isTransparent, setIsTransparent] = React.useState(scrollTransparent);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useLanguage();
  const languageContext = useLanguage();
  const toggleMenu = () => {
    if (collapseOpen) {
      setIsClosing(true);

      setTimeout(() => {
        setIsClosing(false);
        setCollapseOpen(false);
      }, 300);
    } else {
      setCollapseOpen(true);
    }
  };

  const renderBtns = () => {
    var array = [] as any[];
    PagePaths.map((x, index) => {
      array.push(
        <div
          key={index}
          style={{ cursor: "pointer" }}
          onClick={() => {
            // router.push({ pathname: href, query: { u: "true" } });\
            navigate(`/${x.Pathname}`);
            scrollToTop()
          }}
        >
          <div className={styles.smalContainer}>
            <div className={styles.forceHeight}>
              <a className={styles.link}>{t[`nav.${x.Pathname}`]}</a>
            </div>
            <div></div>
          </div>
        </div>
      );
    });

    return array;
  };
  const [scrollingUp, setScrollingUp] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    // 判斷用戶是否向上滾動
    if (currentScrollY < lastScrollY) {
      setScrollingUp(true);
    } else {
      setScrollingUp(false);
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // 清理事件監聽器
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth', // 平滑滚动
    });
};
  return (
    <>
      <div
        className={`${styles.bar} ${
          !isTransparent || collapseOpen || isClosing ? styles.raised : ""
        }`}
      >
        <div
          className={`${styles.bottomIcon} ${
            scrollingUp ? styles.scrolled : ""
          }`}
        >
          <div className={styles.renderLeftBox}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <button
              role="button"
              className={`${styles.menuButton} ${
                collapseOpen ? styles.collapsed : ""
              }`}
              onClick={toggleMenu}
            >
              <div
                className={`${styles.hamburger} ${
                  collapseOpen ? styles.close : ""
                }`}
              >
                <div />
                <div />
                <div />
              </div>
            </button>
          </div>
            <div onClick={toggleMenu} className={styles.block}>{t["website.name"]}</div>
            <div className={styles.renderLeftBar} />
          </div>
          <div
            className={`${styles.menusContainer} ${
              collapseOpen || isClosing ? styles.hideMenu : ""
            }`}
          >
            <div className={styles.renderBtnsBox}>{renderBtns()}</div>

            <div className={styles.renderRightBox}></div>
          </div>

        
          <div className={styles.renderRightBox}>
            {/* <div className={styles.languageBox}>
              <SelectToggle
                title={t[Language.zh]}
                selected={languageContext.language === Language.zh}
                onClick={() => {
                  languageContext.setLanguage(Language.zh);
                  localStorage.setItem("language", Language.zh);
                }}
              />
              <SelectToggle
                title={t[Language.en]}
                selected={languageContext.language === Language.en}
                onClick={() => {
                  languageContext.setLanguage(Language.en);
                  localStorage.setItem("language", Language.en);
                }}
              />
            </div> */}
          </div>
        </div>
      </div>

      <Menu open={isClosing ? false : collapseOpen} toggleMenu={toggleMenu} />
    </>
  );
};

export default Header;
