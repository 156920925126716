import React, {  } from "react";
import styles from "../displays/GridRowsItem.module.scss";
import { GridItem } from "src/types/grid-item";

interface GridRowsItemProps {
  gridContent?: GridItem;
  isBlock?:boolean;
}

const GridRowsItem: React.FC<GridRowsItemProps> = (props) => {
  const { gridContent ,isBlock = false} = props;

  return (
    <div
    style={{cursor:gridContent?.onClick?"pointer":""}}
    className={styles.container} onClick={()=>{
      if(gridContent?.onClick){
        gridContent?.onClick()
      }
    }}>
      {!isBlock&&<>
      {gridContent?.image&&<img src={gridContent?.image} className={styles.image}></img>}
      <div className={styles.content}>
        <div className={styles.itemName}>{gridContent?.item_name}</div>
        <div className={styles?.itemDescriprion}>{gridContent?.item_description}</div>
      </div></>}
    </div>
  );
};

export default GridRowsItem;
