import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useLanguage } from "src/contexts/language/use-language";
import Header from "../navs/Header";
import styles from "../main/PageContainer.module.scss";
import assets from "../../assets/assets.js";

import myImage from "../assets/img/header/sky_banner.jpg";
import Footer from "../footers/Footer";
import { SEO } from "./seo";
interface PageContainerProps {
  fullImage?: string;
  children?: ReactNode;
}

const PageContainer: React.FC<PageContainerProps> = (props) => {
  const { fullImage, children } = props;
  const { t } = useLanguage();

  return (
    <div className={styles.fullPage}>
      <Header />
      {fullImage && (
        <div className={styles.cover}>
          <div className={styles.box}>
            <div className={styles.title}>{t["header.title"]}</div>
            <div className={styles.title}>{t["header.type"]}</div>
            <div className={styles.description}>{t["header.description"]}</div>
          </div>
          <img className={styles.coverImage} src={fullImage} />
        </div>
      )}
      <div className={styles.bar}></div>
      <div className={styles.barBlock}></div>
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default PageContainer;
