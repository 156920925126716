import React, { ReactNode, useEffect } from "react";
import componentStyles from "../../theme/styles/componentStyles.module.scss";
import commonStyles from "../../theme/styles/commonStyle.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SectionTitle from "../displays/SectionTitle";
import styles from "../sections/BigPictureSection.module.scss";

interface BigPictureSectionProps {
  title?: string;
  image?:string;
  description?:string;
  backgroundColor?: string;
  vertical?:boolean;
  children?: ReactNode;
}

const BigPictureSection: React.FC<BigPictureSectionProps> = (props) => {
  const { title,image, description,vertical,children, backgroundColor } = props;

  return (
    <div
      className={commonStyles.pageContainer}
      style={{ backgroundColor: backgroundColor ?? "#FFFFFF" }}
    >
      <div className={componentStyles.sectionContainer}>
        {title&&<SectionTitle title={title} />}
<div className={!vertical?styles.row:styles.column}>
<img src={image} className={!vertical?styles.imgHorizontal:styles.imgVertical}/>
<div className={styles.description}>{description}</div>
</div>
        {children}
      </div>
    </div>
  );
};

export default BigPictureSection;
