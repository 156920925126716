import React, { ReactNode, useEffect } from "react";

import styles from "./Margin.module.scss"

interface MarginProps {
  marginBottom?:number
}

const Margin: React.FC<MarginProps> = (props) => {
  const { marginBottom = 20 } = props;

  return (
     <div style={{marginBottom:marginBottom}}></div>

  );
};

export default Margin;
