import ComputerPage from "src/pages/computer";
import DeepinPage from "src/pages/deepin";
import EnvBuilderPage from "src/pages/env_builder";
import GameDevelopmentPage from "src/pages/game_development";
import Homepage from "src/pages/homepage";
import WebsiteDesignPage from "src/pages/website_design";
import { PathItem } from "src/types/path-item";


export const PagePaths = [
    {Pathname:"homepage",Component:Homepage},
    {Pathname:"deepin",Component:DeepinPage},
    {Pathname:"computer_page",Component:ComputerPage},
    {Pathname:"website_design",Component:WebsiteDesignPage},
    {Pathname:"game_development",Component:GameDevelopmentPage},
    {Pathname:"env_builder",Component:EnvBuilderPage},

    
    

] as PathItem[]