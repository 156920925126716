/*eslint-disable*/

import styles from "./Footer.module.scss";

import { useEffect } from "react";
import { useLanguage } from "src/contexts/language/use-language";

// core components

const Footer = () => {
  const { t } = useLanguage();

  return (
    <footer className={styles.footer}>
      <div className={styles.topLayout}>
        <div className={styles.section}>
          <div className={styles.textBoxes}>
            {/* <div className={styles.textBox}>
            <div className={styles.label}>
              {`${t['info.address']}: `}
            </div>
            <div
              className={styles.text}
              onClick={() => {
                navigator.clipboard.writeText(
                  t["info.address_value"]
                );
                alert(t["alert.copy_done"]+` ${t["info.address_value"]}`);
              }}
            >{t["info.address_value"]}</div>
          </div> */}

            <div className={styles.textBox}>
              <div className={styles.label}>{`${t["info.phone"]}:`}</div>
              <div
                className={styles.text}
                onClick={() => {
                  navigator.clipboard.writeText(t["info.phone_value"]);
                  alert(t["alert.copy_done"] + ` ${t["info.phone_value"]}`);
                }}
              >{`${t["info.phone_value"]} ${t["info.person_name"]}`}</div>
            </div>
            <div className={styles.textBox}>
              <div className={styles.label}>{`${t["info.wechat"]}:`}</div>
              <div
                className={styles.text}
                onClick={() => {
                  navigator.clipboard.writeText(t["info.wechat_value"]);
                  alert(t["alert.copy_done"] + ` ${t["info.wechat_value"]}`);
                }}
              >
                {t["info.wechat_value"]}
              </div>
            </div>
            <div className={styles.textBox}>
              <div className={styles.label}>{`${t["info.email"]}:`}</div>
              <div
                className={styles.text}
                onClick={() => {
                  navigator.clipboard.writeText(t["info.email_value"]);
                  alert(t["alert.copy_done"] + ` ${t["info.email_value"]}`);
                }}
              >
                {t["info.email_value"]}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
