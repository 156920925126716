import React from "react";
import { FC } from "react";
import PageContainer from "src/components/main/PageContainer";
import { useLanguage } from "src/contexts/language/use-language";
import assets from "src/assets/assets";
import BreakLine from "src/components/displays/BreakLine";
import IntroSection from "src/components/sections/IntroSection";
import GridSection from "src/components/sections/GridSection";
import CarouselSection from "src/components/sections/CarouselSection";
interface DeepinPageProps {}

const WebsiteDesignPage: FC<DeepinPageProps> = ({}) => {
  const { t } = useLanguage();



  return (
    <PageContainer fullImage={assets.HEADER_MAIN_BANNER}>
      
 
      <IntroSection
        centerContent={true}
        backgroundColor={`#f7fbff`}

        infoItem={{
          item_title: t["index.title"],
          item_string: t["index.description"],
        }}
      ></IntroSection>

<IntroSection
        centerContent={true}

        infoItem={{
          item_title: t["website_design.example.title"],
          item_string: t["website_design.example.description"],
        }}
      ></IntroSection>
<div style={{ backgroundColor: "#ffffff", height: 50 }}></div>

      <BreakLine />
<div style={{ backgroundColor: "#ffffff", height: 50 }}></div>

<div style={{ backgroundColor: "#f7fbff", height: 50 }}></div>



<CarouselSection
        backgroundColor={`#f7fbff`}
        
        images={[
          assets.ALSO_BLOG_CAROUESL_1,
          assets.ALSO_BLOG_CAROUESL_2,
          assets.ALSO_BLOG_CAROUESL_3,
          assets.ALSO_BLOG_CAROUESL_4,
        ]}
      ></CarouselSection>
      <div style={{ backgroundColor: "#f7fbff", height:25 }}></div>

      <GridSection
              backgroundColor={`#f7fbff`}
        gridItem={[
          {
            item_name: t["website_design.example.feature_1"],
            item_description: "這是一個宣傳網站，適合產品／品牌宣傳，分頁包括主頁，特點，使用方法，用戶評價，聯繫我們，購買鏈接",
            image: assets.ALSO_BLOG_CAROUESL_1,
            onClick: () => {
              window.open(
                "https://static-website-chan.onrender.com/",
                "_blank"
              );
            },
          },

          {
            item_name: t["website_design.example.feature_2"],
            item_description: "這是一個學校網站，適合中小學，供學生與教師瀏覽學校資訊，分頁包括主頁，學校歷史，課程介紹，校園生活，招生信息，聯繫我們",

            image: assets.ALSO_BLOG_CAROUESL_2,
            onClick: () => {
              window.open(
                "https://static-website-school-demo.onrender.com/",
                "_blank"
              );
            },
          },
       
        ]}
      ></GridSection>

      
<GridSection
              backgroundColor={`#f7fbff`}
        gridItem={[
     
          {
            item_name: t["website_design.example.feature_3"],
            item_description: "這是一個公司網站，適合公司簡介/產品/服務展示，分頁包括主頁，服務，留言板，聯絡我們",
            image: assets.ALSO_BLOG_CAROUESL_3,
            onClick: () => {
              window.open("https://mczhuan.com/", "_blank");
            },
          },
          {
            item_name: t["website_design.example.feature_4"],
            item_description: "這是一個公司網站，適合公司簡介/產品/服務展示/公司發展過程解說，分頁包括主頁，貨品清單，香港桶的歷史，聯絡我們",
            image: assets.ALSO_BLOG_CAROUESL_4,
            onClick: () => {
              window.open("https://yk-ee.com/", "_blank");
            },
          },
        ]}
      ></GridSection>
      <div style={{ backgroundColor: "#f7fbff", height: 30 }}></div>
      <div style={{ backgroundColor: "#ffffff", height: 50 }}></div>

      <BreakLine />
      <IntroSection
      
        centerContent={true}
        infoItem={{
          item_title: "網站製作服務",
          item_string: "我們提供幾個套餐，滿足不同客戶的需求",
        }}
      ></IntroSection>
      <div style={{ backgroundColor: "#ffffff", height: 25 }}></div>

      <GridSection gridOfRow={3}
        gridItem={[
          {
            item_name: "3頁靜態網站\n1,120$HKD",
            item_description:"包括主頁，簡介，聯絡我們",

            image:"",
          },  {
            item_name: "4頁靜態網站\n1,600$HKD",
            item_description:"包括主頁，簡介，聯絡我們以及客製化一頁",

            image:"",
          },  {
            item_name: "6頁靜態網站\n2,240$HKD",
            item_description:"包括主頁，簡介，聯絡我們以及客製化三頁",

            image:"",
          }, {
            item_name: "附加費用\n源碼下載 - 50$HKD",
            item_description:"可取得即個網站源碼，以及網站在部處的平台帳號",

            image:"",
          },{
            item_name: "附加費用\n網域兩年 - 220$HKD",
            item_description:"可自訂兩年網域名稱並兩年生效，兩年後必需繼續支付價錢",

            image:"",
          },{
            item_name: "附加費用\n售後服務 - 50$HKD/次",
            item_description:"在網站製作確定完成後，之後可以付50$HKD作內容修改/主題顏色和圖片替換",

            image:"",
          },
        ]}
     />
    </PageContainer>
  );
};

export default WebsiteDesignPage;
