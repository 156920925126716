import React from "react";
import { FC } from "react";
import PageContainer from "src/components/main/PageContainer";
import { useLanguage } from "src/contexts/language/use-language";
import assets from "src/assets/assets";
import BreakLine from "src/components/displays/BreakLine";
import BigPictureSection from "src/components/sections/BigPictureSection";
import IntroSection from "src/components/sections/IntroSection";
import GridSection from "src/components/sections/GridSection";
import BigPicturePointsSection from "src/components/sections/BigPicturePointsSection";
interface ComputerPageProps {}

const ComputerPage: FC<ComputerPageProps> = ({}) => {
  const { t } = useLanguage();
const model_1_points =new Array(13).fill(0);
const model_2_points =new Array(12).fill(0);


  return (
    <PageContainer fullImage={assets.HEADER_MAIN_BANNER}>
      
      <IntroSection
        centerContent={true}
        infoItem={{
          item_title: t["computer_page.title"],
          item_string: t["computer_page.description"],
        }}
      ></IntroSection>


<div style={{ backgroundColor: "#ffffff", height: 50 }}></div>


      <BreakLine />
      

      <div style={{ backgroundColor: "#ffffff", height: 50 }}></div>
      <div style={{ backgroundColor: "#f7fbff", height: 25 }}></div>
      <IntroSection
        centerContent={true}
        backgroundColor={`#f7fbff`}
        infoItem={{
          item_title: t["computer_page.intro.title"],
          item_string: t["computer_page.intro.description"],
        }}
      ></IntroSection>
      <div style={{ backgroundColor: "#f7fbff", height: 25 }}></div>

       <GridSection gridOfRow={4}
           backgroundColor={`#f7fbff`}
        gridItem={[
          {
            item_name: t["computer_page.intro.feature_1"],
            item_description:t["computer_page.intro.feature_1_description"],

            image:"",
          },
          {
            item_name: t["computer_page.intro.feature_2"],
            item_description:t["computer_page.intro.feature_2_description"],

            image:"",
          },    {
            item_name: t["computer_page.intro.feature_3"],
            item_description:t["computer_page.intro.feature_3_description"],

            image:"",
          },    {
            item_name: t["computer_page.intro.feature_4"],
            item_description:t["computer_page.intro.feature_4_description"],
            image:"",
          }
        ]}
     />
<div style={{ backgroundColor: "#f7fbff", height: 50 }}></div>
<div style={{ backgroundColor: "#ffffff", height: 50 }}></div>
     
<BreakLine/>
<div style={{ backgroundColor: "#ffffff", height: 50 }}></div>
      {/*
<IntroSection
        centerContent={true}
        backgroundColor={`#f7fbff`}
        infoItem={{
          item_title: t["computer_page.model_1.title"],
          item_string: t["computer_page.model_1.description"],
        }}
      ></IntroSection>
<BigPicturePointsSection
        backgroundColor={`#f7fbff`}
        descriptions={model_1_points.map((x,index)=>{return t[`computer_page.model_1.feature_${index+1}`]})}
        image={assets.ALSO_BLOG_MINI_COMPUTER}
      />
<div style={{ backgroundColor: "#ffffff", height: 50 }}></div>

  <IntroSection
        centerContent={true}
        infoItem={{
          item_title: t["computer_page.model_2.title"],
          item_string: t["computer_page.model_2.description"],
        }}
      ></IntroSection>
<BigPicturePointsSection
        descriptions={model_2_points.map((x,index)=>{return t[`computer_page.model_2.feature_${index+1}`]})}
        image={assets.ALSO_BLOG_MINI_COMPUTER}
      /> */}
    </PageContainer>
  );
};

export default ComputerPage;
