import React, { ReactNode, useEffect } from "react";
import componentStyles from "../../theme/styles/componentStyles.module.scss";
import commonStyles from "../../theme/styles/commonStyle.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import IntroTitle from "../displays/IntroTitle";
import { InfoItem } from "src/types/info-item";

interface IntroSectionProps {

  infoItem:InfoItem;
  backgroundColor?: string;
centerContent?:boolean;
  children?: ReactNode;
}

const IntroSection: React.FC<IntroSectionProps> = (props) => {
  const { infoItem, children,centerContent, backgroundColor } = props;

  return (
    <div
      className={commonStyles.pageContainer}
      style={{ backgroundColor: backgroundColor ?? "#FFFFFF" }}
    >
      <div className={`${centerContent?componentStyles.sectionContainerCenter:componentStyles.sectionContainer} }`}>
        {infoItem.item_title&&<IntroTitle title={infoItem.item_title} />}

        {infoItem.item_string&&<div className={centerContent?componentStyles.infoStringCenterText:componentStyles.infoStringText}>{infoItem.item_string}</div>}
        {children}
      </div>
    </div>
  );
};

export default IntroSection;
