import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import { i18n } from 'src/i18n';

const LanguageContext = createContext({} as any);

import { reducer } from './language.reducer';

const INITIAL_STATE = {
  language: "zh",
};

const useLanguageActions = (initialLanguage = INITIAL_STATE) => {
  const [state, dispatch] = useReducer(reducer, initialLanguage);

  const setLanguageHandler = (language: number) => {
    dispatch({
      type: 'SET_LANGUAGE',
      payload: language,
    });
  };

  return {
    state,
    setLanguageHandler,
  };
};

interface LanguageProviderProps {
  children?: ReactNode;
}

export const LanguageProvider: React.FunctionComponent<LanguageProviderProps> = (props) => {
  const { children } = props;
  const { state, setLanguageHandler } = useLanguageActions();

  return (
    <LanguageContext.Provider
      value={{
        language: state.language,
        t:i18n[state.language],
        setLanguage: setLanguageHandler
      }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
