// App.tsx
import React from "react";
import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SEO } from "./components/main/seo";
import { PagePaths } from "./components/page-paths";
import { useLanguage } from "./contexts/language/use-language";
import Homepage from "./pages/homepage";
import { DemoApi } from "./lib/delete-family-relation/demo-api";
const App: React.FC = () => {
  const { t } = useLanguage();
  const languageContext = useLanguage();
  // useEffect(() => {
  //   document.title = 'My Page Title';
  // }, []);
  const demoApi = async ()=>{
    const result = await DemoApi();
alert(JSON.stringify(result))
  }
  useEffect(() => {


    // demoApi();
    languageContext.setLanguage(localStorage.getItem("language")??"zh");
    const handleWindowResize = () => {
      const root = document.documentElement;
      root.style.setProperty("--window-width", `${window.innerWidth}px`);
    };
    window.addEventListener("resize", handleWindowResize);
    handleWindowResize();

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  // useEffect(() => {

  //   alert(languageContext.language)
  // }, [languageContext.language]);
  return (
   <>
      <Router>
        <Routes>
          <Route path={`/`} element={<Homepage />} />
          {/* <Route path={`/homepage`} element={<Homepage />} />
        <Route path={`/about-us`} element={<AboutUs />} /> */}

          {PagePaths.map(({ Pathname, Component }) => (
            <Route
              key={Pathname}
              path={`/${Pathname}`}
              element={<Component />}
            />
          ))}
        </Routes>
      </Router>
      </>
  );
};

export default App;
