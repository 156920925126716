import React, { ReactNode, useEffect } from "react";
import componentStyles from "../../theme/styles/componentStyles.module.scss";
import commonStyles from "../../theme/styles/commonStyle.module.scss";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import SectionTitle from "../displays/SectionTitle";
import styles from "../sections/CarouselSection.module.scss";
import Margin from "../displays/Margin";

interface CarouselSectionProps {
  images: string[];
  title?: string;
  backgroundColor?: string;
  children?: ReactNode;
}

const CarouselSection: React.FC<CarouselSectionProps> = (props) => {
  const { images, title, children, backgroundColor } = props;

  return (
    <div
      className={commonStyles.pageContainer}
      style={{ backgroundColor: backgroundColor ?? "#FFFFFF" }}
    >
      <div className={componentStyles.carouselContainer}>
        {title&&<SectionTitle title={title} />}
        

        <Carousel
          showArrows={true}
          infiniteLoop={true}
          emulateTouch={true}
          showThumbs={false}
        >
          {images.map((image, index) => (
            <div key={index}>
              <img
              key={index}
                className={componentStyles.carouselImg}
                src={image}
                alt={`Slide ${index}`}
              />
            </div>
          ))}
        </Carousel> <Margin />
        {children}
      </div>
    </div>
  );
};

export default CarouselSection;
