import React, { ReactNode, useEffect } from "react";
import componentStyles from "../../theme/styles/componentStyles.module.scss";
import commonStyles from "../../theme/styles/commonStyle.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SectionTitle from "../displays/SectionTitle";
import { GridItem } from "src/types/grid-item";
import assets from "src/assets/assets";
import GridRowsItem from "../displays/GridRowsItem";
import styles from "../sections/GridSection.module.scss";

interface GridSectionProps {
  title?: string;

  backgroundColor?: string;
  gridItem: GridItem[];
  children?: ReactNode;
  gridOfRow?:number;
}

const GridSection: React.FC<GridSectionProps> = (props) => {
  const { title, children,gridOfRow = 3, gridItem, backgroundColor } = props;

  const renderItem = () => {
    var row = [] as any[];
    var column = [] as any[];
    gridItem.map((x, index) => {
      if (row.length < gridOfRow) {
        row.push(<GridRowsItem gridContent={x} />);
        if (gridItem.length - 1 === index) {
          // for (let i =gridOfRow - row.length; i > 0; i--) {
          //   row.push(<GridRowsItem isBlock={true} />);
          //   console.log("i: " + i);
          // }
          column.push(<div className={styles.row}>{row}</div>);
          row = []
        }
      } else {
        
        if (gridItem.length - 1 === index) {
          
          if (row.length === gridOfRow) {
            column.push(<div className={styles.row}>{row}</div>);
            row = [];
            row.push(<GridRowsItem gridContent={x} />);
          }
          // for (let i = gridOfRow - row.length; i > 0; i--) {
          //   row.push(<GridRowsItem isBlock={true} />);
          //   console.log('row.lengthrow.length: '+row.length)
          //   console.log("i: " + i);
          // }
          column.push(<div className={styles.row}>{row}</div>);
        } else {
          
          column.push(<div className={styles.row}>{row}</div>);
          row = [];

          row.push(<GridRowsItem gridContent={x} />);

        }
      }
    });

    return <div className={styles.column}>{column}</div>;
  };

  return (
    <div
      className={commonStyles.pageContainer}
      style={{ backgroundColor: backgroundColor ?? "#FFFFFF" }}
    >
      <div className={componentStyles.sectionContainer}>
        {title&&<SectionTitle title={title} />}
        {renderItem()}

        {children}
      </div>
    </div>
  );
};

export default GridSection;
