import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useLanguage } from "src/contexts/language/use-language";
import Header from "../navs/Header";
import commonStyle from "../../theme/styles/commonStyle.module.scss"
import componentStyles from "../../theme/styles/componentStyles.module.scss"

import styles from "./PageTitle.module.scss"

interface BreakLineProps {
  backgroundColor?:string;
}

const BreakLine: React.FC<BreakLineProps> = (props) => {

  return (
      <div className={commonStyle.breaklineContainer}style={{backgroundColor:props.backgroundColor}}>
        
        <div className={styles.minBox} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.minBox} />

      </div>

  );
};

export default BreakLine;
