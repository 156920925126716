
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "src/contexts/language/use-language";
import { Language } from "src/types/language";
import SelectToggle from "../inputs/SelectToggle";
import { PagePaths } from "../page-paths";
import styles from "./Menu.module.scss";

interface MenuProps {
  open: boolean;
  toggleMenu: () => void;
}

const Menu: React.FC<MenuProps> = ({ open, toggleMenu }) => {
  const navigate = useNavigate();
  const { t } = useLanguage();
  const languageContext = useLanguage();
  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth', // 平滑滚动
    });
};
  const renderBtns = () => {
    var array = [] as any[];
    PagePaths.map((x, index) => {
      array.push(
        <div
          key={index}
          style={{ cursor: "pointer" }}
          onClick={() => {
            // router.push({ pathname: href, query: { u: "true" } });\
            navigate(`/${x.Pathname}`);
            scrollToTop()
          }}
        >
         
         {t[`nav.${x.Pathname}`]}
        </div>
      );
    });

    return array;
  };
  return (
    <div className={`${styles.root} ${open ? styles.open : ""}`}>
      
      <div className={styles.wrapper}>

        {/* <Link href={`/${locale}#aboutUs`} passHref> */}
      
          <a className={styles.link} onClick={toggleMenu}>
     
            {renderBtns()}
          </a>
        {/* </Link> */}
   
      </div>
    </div>
  );
};

export default Menu;
