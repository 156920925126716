import React from "react";
import { FC } from "react";
import PageContainer from "src/components/main/PageContainer";
import { useLanguage } from "src/contexts/language/use-language";
import CarouselSection from "src/components/sections/CarouselSection";
import assets from "src/assets/assets";
import InfoSection from "src/components/sections/InfoSection";
import GridSection from "src/components/sections/GridSection";
import { GridItem } from "src/types/grid-item";
import {
  demoFeaturePointItem,
  demoGridItems,
  demoInfoItem,
  demoTabItems,
} from "src/components/demo-data";
import TabsSection from "src/components/sections/TabsSection";
import BigPictureSection from "src/components/sections/BigPictureSection";
import { InfoItem } from "src/types/info-item";
import FeaturePointSection from "src/components/sections/FeaturePointSection";
import componentStyles from "../../theme/styles/componentStyles.module.scss";
import commonStyles from "../../theme/styles/commonStyle.module.scss";
import Margin from "src/components/displays/Margin";
import { sectionColor } from "../../theme/styles/themeColor";
import IntroSection from "src/components/sections/IntroSection";
import BreakLine from "src/components/displays/BreakLine";
import styles from "./Homepage.module.scss";
interface HomepageProps {}

const Homepage: FC<HomepageProps> = ({}) => {
  const { t } = useLanguage();

  return (
    <PageContainer fullImage={assets.HEADER_MAIN_BANNER}>
      <IntroSection
        centerContent={true}
        infoItem={{
          item_title: t["header.section.title"],
          item_string: t["header.section.description"],
        }}
      ></IntroSection>



<div style={{ backgroundColor: "#ffffff", height: 50 }}></div>

      <BreakLine />
    <div style={{ backgroundColor: "#ffffff", height: 50 }}></div>
      
      <IntroSection
       backgroundColor={`#f7fbff`}
      centerContent={true}
      infoItem={{
        item_title:"我們的服務",
        item_string:"我們提供多個領域範疇服務，包括軟硬件及教學，並持續評估和挑戰新技術提供新服務",
      }}
    ></IntroSection>
    <div style={{ backgroundColor: "#f7fbff", height: 25 }}></div>

    <GridSection gridOfRow={3}
       backgroundColor={`#f7fbff`}

      gridItem={[
        {
          item_name: "裝系統服務",
          item_description:"電腦裝系統包括WINDOW11，DEEPIN並提供裝機USB手指，可選擇只要USB手指或只裝機",

          image:"",
        },
        {
          item_name: "網頁製作",
          item_description:"包括公司網站/個人網站/作品集/靜態宣傳網站，可支付附加費取得即個網站源碼，以及網站在部處的平台帳號/兩年網域名稱/售後服務",

          image:"",
        },
        
        {
          item_name: "全新微型電腦組裝",
          item_description:"經過壓力測試的全新微型電腦，用途範圍一般包括編程/文書/4K影片/休閒遊戲如LOL或原神，可提供優惠價裝系統，優惠價開發環境搭建，總價格通常不高於2,899$HKD",

          image:"",
        },
        {
          item_name: "Unity 2D/3D教學",
          item_description:"提供入門至中階遊戲開發技術支援，一般包括UI/語言切換/PC或Android平台成品輸出/小遊戲編程解決方案",

          image:"",
        },
        {
          item_name: "開發環境搭建",
          item_description:"包括React/React Native開發環境搭建支援，可提供GitHub項目編寫處理，收費通常以小時計起，React Native只限於Android平台開發",

          image:"",
        },
      ]}
   />
    <div style={{ backgroundColor: "#f7fbff", height: 25 }}></div>
    <div style={{ backgroundColor: "#ffffff", height: 50 }}></div>

    <BreakLine />
   
      <div style={{ backgroundColor: "#ffffff", height: 50 }}></div>
      <div style={{ backgroundColor: "#f7fbff", height: 25 }}></div>

      <IntroSection
        backgroundColor={`#f7fbff`}

        centerContent={true}
        infoItem={{
          item_title: t["homepage.farewell.title"],
          item_string: "",
        }}
      ></IntroSection>

<div style={{ backgroundColor: "#f7fbff", height: 25 }}></div>

      <BigPictureSection
        backgroundColor={`#f7fbff`}

        title=""
        description={t["homepage.farewell.description"]}

        image={assets.ALSO_BLOG_START}
        vertical={true}
      />
      <div style={{ backgroundColor: "#f7fbff", height: 50 }}></div>
      <div style={{ backgroundColor: "#ffffff", height: 50 }}></div>

    </PageContainer>
  );
};

export default Homepage;
