import ALSO_BLOG_CAROUESL_1 from './img/also_blog/carouesl_1.jpeg'
import ALSO_BLOG_CAROUESL_2 from './img/also_blog/carouesl_2.jpeg'
import ALSO_BLOG_CAROUESL_3 from './img/also_blog/carouesl_3.jpeg'
import ALSO_BLOG_CAROUESL_4 from './img/also_blog/carouesl_4.jpeg'
import ALSO_BLOG_CHAT from './img/also_blog/chat.png'
import ALSO_BLOG_FAST from './img/also_blog/fast.png'
import ALSO_BLOG_GROWTH from './img/also_blog/growth.png'
import ALSO_BLOG_LOW_COST from './img/also_blog/low_cost.png'
import ALSO_BLOG_MINI_COMPUTER from './img/also_blog/mini_computer.jpg'
import ALSO_BLOG_PRODUCTS from './img/also_blog/products.png'
import ALSO_BLOG_SEARCH from './img/also_blog/search.png'
import ALSO_BLOG_START from './img/also_blog/start.jpg'
import GRID_ITEM_DEMO_ICON_1 from './img/grid_item/demo_icon_1.png'
import GRID_ITEM_DEMO_ICON_2 from './img/grid_item/demo_icon_2.png'
import GRID_ITEM_DEMO_ICON_3 from './img/grid_item/demo_icon_3.png'
import GRID_ITEM_DEMO_ICON_4 from './img/grid_item/demo_icon_4.png'
import HEADER_DEMO_1 from './img/header/demo_1.jpg'
import HEADER_DEMO_2 from './img/header/demo_2.jpg'
import HEADER_MAIN_BANNER from './img/header/main_banner.jpg'


const assets = {
  ALSO_BLOG_CAROUESL_1:ALSO_BLOG_CAROUESL_1,
  ALSO_BLOG_CAROUESL_2:ALSO_BLOG_CAROUESL_2,
  ALSO_BLOG_CAROUESL_3:ALSO_BLOG_CAROUESL_3,
  ALSO_BLOG_CAROUESL_4:ALSO_BLOG_CAROUESL_4,
  ALSO_BLOG_CHAT:ALSO_BLOG_CHAT,
  ALSO_BLOG_FAST:ALSO_BLOG_FAST,
  ALSO_BLOG_GROWTH:ALSO_BLOG_GROWTH,
  ALSO_BLOG_LOW_COST:ALSO_BLOG_LOW_COST,
  ALSO_BLOG_MINI_COMPUTER:ALSO_BLOG_MINI_COMPUTER,
  ALSO_BLOG_PRODUCTS:ALSO_BLOG_PRODUCTS,
  ALSO_BLOG_SEARCH:ALSO_BLOG_SEARCH,
  ALSO_BLOG_START:ALSO_BLOG_START,
  GRID_ITEM_DEMO_ICON_1:GRID_ITEM_DEMO_ICON_1,
  GRID_ITEM_DEMO_ICON_2:GRID_ITEM_DEMO_ICON_2,
  GRID_ITEM_DEMO_ICON_3:GRID_ITEM_DEMO_ICON_3,
  GRID_ITEM_DEMO_ICON_4:GRID_ITEM_DEMO_ICON_4,
  HEADER_DEMO_1:HEADER_DEMO_1,
  HEADER_DEMO_2:HEADER_DEMO_2,
  HEADER_MAIN_BANNER:HEADER_MAIN_BANNER,

}

export default assets;

