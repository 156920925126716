import React from "react";
import { FC } from "react";
import PageContainer from "src/components/main/PageContainer";
import { useLanguage } from "src/contexts/language/use-language";
import assets from "src/assets/assets";
import BreakLine from "src/components/displays/BreakLine";
import IntroSection from "src/components/sections/IntroSection";
import GridSection from "src/components/sections/GridSection";
interface GameDevelopmentPageProps {}

const GameDevelopmentPage: FC<GameDevelopmentPageProps> = ({}) => {
  const { t } = useLanguage();



  return (
    <PageContainer fullImage={assets.HEADER_MAIN_BANNER}>
      
      <IntroSection
        centerContent={true}

        infoItem={{
          item_title: "Unity遊戲教學服務",
          item_string: "一對一指導開發，推薦入門級/初學者/小遊戲開發者",
        }}
      ></IntroSection>
<div style={{ backgroundColor: "#ffffff", height: 50 }}></div>

      <BreakLine />
      <div style={{ backgroundColor: "#ffffff", height: 50 }}></div>
<div style={{ backgroundColor: "#f7fbff", height:25}}></div>

      <IntroSection
        centerContent={true}
        backgroundColor={`#f7fbff`}

        infoItem={{
          item_title: "教學範圍",
          item_string: "通常提供2D/3D小遊戲的教學或解決方案",
        }}
      ></IntroSection>
<div style={{ backgroundColor: "#f7fbff", height: 25 }}></div>

<GridSection gridOfRow={3}
     backgroundColor={`#f7fbff`}
  gridItem={[
    {
      item_name: "遠端安裝Unity程式",
      item_description:"可透過遠端控制解決Unity的常見安裝問題",

      image:"",
    },
    {
      item_name: "遊戲UI搭建",
      item_description:"找尋遊戲理想的UI搭建模式，助日後的界面重用和可擴性提出處理模式",

      image:"",
    }, 
    
    // {
    //   item_name: t["deepin.feature_6"],
    //   item_description:t["deepin.feature_6_description"],

    //   image:"",
    // },  
  ]}
/>
<GridSection gridOfRow={3}
     backgroundColor={`#f7fbff`}
  gridItem={[
   {
      item_name: "小遊戲方案",
      item_description:"溝通確認後提供小遊戲框架或設計流程，可在付費後的有限時間內不停改進小遊戲的系統或提供新方案",

      image:"",
    },    {
      item_name:"語言切換功能",
      item_description:"幫助客戶設計語言切換功能，可以不用Asset store上的第三方Plugin轉用原代碼搭建",

      image:"",
    } 
    
    // {
    //   item_name: t["deepin.feature_6"],
    //   item_description:t["deepin.feature_6_description"],

    //   image:"",
    // },  
  ]}
/>
<GridSection gridOfRow={3}
     backgroundColor={`#f7fbff`}
  gridItem={[
      {
      item_name:"PC/Android 成品輸出",
      item_description:"當項目完成開發，可以遠端幫助客戶輸出成品仍測試用，但暫時不支持平台上架",

      image:"",
    },  
    {
      item_name: "客戶現有項目問題處理",
      item_description:"可提供客戶現有項目代碼，盡可能保持代碼完整或者找尋新方案代替",

      image:"",
    },  
  ]}
/>
<div style={{ backgroundColor: "#f7fbff", height: 50 }}></div>

<div style={{ backgroundColor: "#ffffff", height: 50 }}></div>

<BreakLine />
      

      <IntroSection
      
        centerContent={true}
        infoItem={{
          item_title:  "教學收費",
          item_string: "通常以小時計算費用，也可以以一個項目完成計算費用但需要支付訂金",
        }}
      ></IntroSection>
      <div style={{ backgroundColor: "#ffffff", height: 25 }}></div>

      <GridSection gridOfRow={3}
        gridItem={[
          {
            item_name: "小時計",
            item_description:"每小時150$HKD",

            image:"",
          },     {
            item_name: "半日計",
            item_description:"半日6小時 799$HKD",

            image:"",
          },   {
            item_name: "一個項目計",
            item_description:"每個項目4,199$HKD，通常只接受小規模遊戲開發",

            image:"",
          },
          // {
          //   item_name: t["deepin.service.feature_2"],
          //   item_description:t["deepin.service.feature_2_description"],

          //   image:"",
          // },   
       
        ]}
     />
<div style={{ backgroundColor: "#ffffff", height: 50 }}></div>
     

    </PageContainer>
  );
};

export default GameDevelopmentPage;
